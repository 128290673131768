import React, { useEffect } from 'react';
import styled from 'styled-components';
import NotFound from '../not-found/NotFound';
import { useTranslation } from 'react-i18next';
import { Spoiler } from '@mantine/core';




const Notifications = () => {
  const { t } = useTranslation();


  const notificationsData = [
    {
      id: 1,
      title: t("conditionalDonationOpportunity"),
      description: t("conditionalDonationDescription"),
      date: '2024-09-10',
      image: 'https://via.placeholder.com/150', // Example image URL
    },
    {
      id: 2,
      title: t("matchAndSuccessSupport"),
      description: t("matchAndSuccessSupportDescription"),
      date: '2024-09-12',
      image: 'https://via.placeholder.com/150', // Example image URL
    },
    {
      id: 3,
      title: t("transferSupportHasBegun"),
      description: t("transferSupportDescription"),
      date: '2024-09-15',
      image: 'https://via.placeholder.com/150', // Example image URL
    },
  ];

  useEffect(() => {
    // Fetch notifications data if needed
  }, []);

  return (
    <NotificationsMain>
      <CardContainer>
        {notificationsData.length ? (
          notificationsData.map(notification => (
            <NotificationCard key={notification.id}>
              <ImageContainer>
                <img src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/c039dc19-4d88-4b95-0180-445a54c94c00/original" alt={notification.title} />
              </ImageContainer>
              <CardContent>
                <Title>{t(notification.title)}</Title>
                <Spoiler maxHeight={80} showLabel={t('showMore')} hideLabel={t('showLess')}>
                  <p>{t(notification.description)}</p>
                </Spoiler>
                <small>{t(notification.date)}</small>
              </CardContent>
            </NotificationCard>
          ))
        ) : (
          <NotFound text={`${t('searchResults')} ${t('notFound')}`} />
        )}
      </CardContainer>
    </NotificationsMain>
  );
};

export default Notifications;

export const NotificationsMain = styled.main`
  background-color: #f8f8f8;
  padding: 80px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

export const NotificationCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  padding: 0;
  width: 100%;
  max-width: 370px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  }
`;

export const ImageContainer = styled.div`
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 20px 0;
    background-color: #033036;
  }
`;

export const CardContent = styled.div`
  padding: 20px;

  h3 {
    font-size: 1.4em;
    margin: 0;
    color: #333;
  }

  p {
    font-size: 1em;
    color: #555;
    margin: 10px 0 15px;
  }

  small {
    font-size: 0.85em;
    color: #888;
  }
`;
export const Title = styled.h3`
  font-size: 1.4em;
  margin: 0;
  color: #333;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: 600px) {
    width: 90%; // Adjust width as needed
  }
`;