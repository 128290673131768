import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLanguage, setLanguageId, setCurrency } from "../../redux/features/filter/filterSlice";
import { TbWorld } from "react-icons/tb";
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { LanguageData } from "../../components";
import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { getStartLanguage } from "../../logic/getStartLanguage";

const LanguageButton = ({ color, showIcon, border, minWidth }) => {

  const [startCountryId, setStartCountryId] = useState("");
  const [tempLanguage, setTempLanguage] = useState("");
  const [tempLanguageData, setTempLanguageData] = useState("");
  const [tempCurrencyData, setTempCurrencyData] = useState("€");
  const [tempCurrency, setTempCurrency] = useState("");
  const [languageOpen, setLanguageOpen] = useState(false);
  const [currencyOpen, setCurrencyOpen] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCurrency, setSelectedCurrency] = useState(localStorage.getItem("currencyFullName") ? localStorage.getItem("currencyFullName") : "€ Euro    EUR");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("languageLabelTag") 
    ? localStorage.getItem("languageLabelTag") 
    : navigator.language
    );

  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const languages = LanguageData();

  const handleChangeLanguage = (languageCode) => {
    const selectedLanguageLabel = languages.find((lang) => lang.code === languageCode).languageName;
    const selectedLanguageTag   = languages.find((lang) => lang.code === languageCode).languageCode;
    const selectedLanguageFullName = selectedLanguageLabel + " " + (
      selectedLanguageTag ? `(${selectedLanguageTag})` : ''
    );
    setSelectedLanguage(selectedLanguageFullName);
    setTempLanguage(languageCode);
    setLanguageOpen(false);  
  }

  const handleChangeCurrency = (currency) => {
    const currencySymbols = currency.symbols;
    const currencyLabel = currency.label;
    const currencyCode = currency.code;
    const currencyFullName = currencySymbols + " " + currencyLabel + " " + currencyCode;
    localStorage.setItem("currencyFullName",currencyFullName);
    setSelectedCurrency(currencyFullName);
    setTempCurrency(currency.number);
    setCurrencyOpen(false);
  }

  const handleSaveChanges = () => {
    if (tempLanguage) {   
      i18n.changeLanguage(tempLanguage);
      const selectedtag = languages.find((lang) => lang.code === tempLanguage).languageCode;
      const selectedLabel = languages.find((lang) => lang.code === tempLanguage).languageName;
      const selectedLanguageFullName = selectedLabel + " " + (
        selectedtag ? `(${selectedtag})` : ''
      );
      const selectedId = languages.find((lang) => lang.code === tempLanguage).languageId;
      dispatch(setLanguage(tempLanguage));
      dispatch(setLanguageId(selectedId));
      localStorage.setItem("languageId", selectedId);
      setTempLanguageData(selectedtag);
      setSelectedLanguage(selectedLabel + " " +selectedtag);
      localStorage.setItem("selectedLanguage", tempLanguage);
      localStorage.setItem("languageLabelTag", selectedLanguageFullName);

      const selectedLanguageCode = languages.find((lang) => lang.code === i18n.language).languageCode;  
      localStorage.setItem("selectedLanguageCode",selectedLanguageCode)
      localStorage.setItem("tag",selectedtag);
    }
    if (tempCurrency) {
      const selectedSymbols = currency.find((lang) => lang.number === tempCurrency).symbols;
      const currencyCode = currency.find((lang) => lang.number === tempCurrency).number;
      const currencyIso = currency.find((lang) => lang.number === tempCurrency).code;
      dispatch(setCurrency(tempCurrency));
      setTempCurrencyData(selectedSymbols);
      localStorage.setItem("selectedCurrency", selectedSymbols);
      localStorage.setItem("selectedCurrencyCode", currencyCode);
      localStorage.setItem("selectedCurrencyIso", currencyIso);
    }
    close();
    const startLanguage = getStartLanguage();
    window.location.href = `/${startLanguage}`;
  }
 
  const getCountryCodeFromCoordinates = (latitude, longitude) => {

    const countryCoordinates = {
      "TRY": { latRange: [36, 42], lonRange: [26, 45] }, // Türkiye
      "US": { latRange: [24, 49], lonRange: [-125, -66] }, // Amerika
      "GB": { latRange: [50, 59], lonRange: [-10, 2] }, // İngiltere
      "ES": { latRange: [36, 44], lonRange: [-9, 4] },  // İspanya
      "FR": { latRange: [42, 51], lonRange: [-5, 9] },   // Fransa
      "IT": { latRange: [36, 47], lonRange: [7, 19] },   // İtalya
    };

    let userCountry = null;

    for (const countryCode in countryCoordinates) {
      const latRange = countryCoordinates[countryCode].latRange;
      const lonRange = countryCoordinates[countryCode].lonRange;

      if (latitude >= latRange[0] && latitude <= latRange[1] && longitude >= lonRange[0] && longitude <= lonRange[1]) {
        userCountry = countryCode;
        break;
      }
    }
    return userCountry;
  }
 
  const currency = [
    { code: "TRY", label: t("currencyTR"), number: "949", symbols:"₺" },
    { code: "USD", label: t("currencyUS"), number: "840", symbols:"$"},
    { code: "EUR", label: t("currencyEUR"), number: "978", symbols:"€"},
  ]

  useEffect(()=> {
    const originalLanguage = navigator.language;
    const newLanguage = originalLanguage.split('-')[0];
    const storedLanguage = localStorage.getItem("selectedLanguage");    
    const startLanguage = storedLanguage ? storedLanguage : newLanguage;

      const startLanguageTag = languages.find((lang) => lang.code === startLanguage);
      setStartCountryId(startLanguageTag ? startLanguageTag.languageId : "")
      setTempLanguageData(startLanguageTag ? startLanguageTag.languageCode : "");

      localStorage.setItem("startCountryId",startCountryId)

      
      // const selectedLanguageLabel = languages.find((lang) => lang.code === startLanguage);
      // const selectedLanguageTag   = languages.find((lang) => lang.code === startLanguage);
      // setSelectedLanguage(selectedLanguageLabel ? selectedLanguageLabel.languageName + " " + selectedLanguageTag.tag : "");

      // const newLangTag = localStorage.getItem("languageLabelTag") ? localStorage.getItem("languageLabelTag") : selectedLanguage;
      // setSelectedLanguage(newLangTag);
      // localStorage.setItem("initFullName", fullName);
  },[languages])
 
  useEffect(() => {

    const storedCurrencyCode = localStorage.getItem("selectedCurrencyCode");

    if (!storedCurrencyCode) {
      localStorage.setItem("selectedCurrencyIso", "EUR");

      const secondCurrency = currency.find((cur) => cur.code === "EUR");
  
      const crNumber = secondCurrency.number;
      localStorage.setItem("selectedCurrencyCode",crNumber)
  
      const getCurrency = localStorage.getItem("selectedCurrency");
      const storedCurrency = currency.find((cur) => cur.symbols === getCurrency);
  
      const initialCurrency = storedCurrency || secondCurrency;
  
      if(secondCurrency){
        dispatch(setCurrency(initialCurrency));
        const currencySymbols = initialCurrency.symbols;
        const currencyLabel = initialCurrency.label;
        const currencyCode = initialCurrency.code;
        const currencyFullName = currencySymbols + " " + currencyLabel + " " + currencyCode;
        setSelectedCurrency(currencyFullName);
        setTempCurrency(initialCurrency.number);
        setTempCurrencyData(initialCurrency.symbols);
      }
    if (storedCurrencyCode) {
      localStorage.setItem("selectedCurrencyIso", "EUR");

      const secondCurrency = currency.find((cur) => cur.code === "EUR");
  
      const crNumber = secondCurrency.number;
      localStorage.setItem("selectedCurrencyCode",crNumber)
  
      const getCurrency = localStorage.getItem("selectedCurrency");
      const storedCurrency = currency.find((cur) => cur.symbols === getCurrency);
  
      const initialCurrency = storedCurrency || secondCurrency;
  
      if(secondCurrency){
        dispatch(setCurrency(initialCurrency));
        const currencySymbols = initialCurrency.symbols;
        const currencyLabel = initialCurrency.label;
        const currencyCode = initialCurrency.code;
        const currencyFullName = currencySymbols + " " + currencyLabel + " " + currencyCode;
        setSelectedCurrency(currencyFullName);
        setTempCurrency(initialCurrency.number);
        setTempCurrencyData(initialCurrency.symbols);
      }
    }
    }  
  }, [])
  

  return (
    <>
      <LanguageDropdownContainer>

        <LanguageButtonWrapper 
          minWidth={minWidth}
          border={border}
          style={{ color: color }}
          onClick={open}
        >
          <IconLanguageContainer>
            <TbWorld size={22}/>
            ({ localStorage.getItem("tag") ? localStorage.getItem("tag") : tempLanguageData })
              -
            <span>{localStorage.getItem("selectedCurrency") ? localStorage.getItem("selectedCurrency") : tempCurrencyData}</span> 
          </IconLanguageContainer>  
          {showIcon && <IoMdArrowDropdown size={20} />}    
        </LanguageButtonWrapper>

        <Modal opened={opened} onClose={() => { close() }} title={t("languageCurrency")} centered>
          
          <ModalContent>

            <LanguageButtonChange 
                onClick={() => setLanguageOpen(!languageOpen)}>
              <IconLanguageContainer>
                <TbWorld size={22}/>
                <span>{ selectedLanguage }</span>
              </IconLanguageContainer>
              <IoMdArrowDropdown size={20} />
            </LanguageButtonChange>

            {languageOpen && (
              <>
                <LanguageSelect>
                  {languages.map((lang) => (
                    <LanguageOption
                      key={lang.code}
                      onClick={() => handleChangeLanguage(lang.code)}
                    >
                      {lang.flag}
                      <LanguageFlagLabel>{lang.languageName}</LanguageFlagLabel>
                    </LanguageOption>
                  ))}
                </LanguageSelect>
              </>
            )}

            <CurrencyButtonChange 
              onClick={() => setCurrencyOpen(!currencyOpen)}
            > 
              <span>{selectedCurrency}</span>
              <IoMdArrowDropdown size={20} />
            </CurrencyButtonChange>

            {currencyOpen && (
              <>
                <CurrencySelect>
                  {currency.map((cur) => (
                    <CurrencyOption 
                      key={cur.code}
                      onClick={() => handleChangeCurrency(cur)}
                    >
                    <CurrencyInfo>
                      <CurrencySymbol>{cur.symbols}</CurrencySymbol>
                      <CurrencyLabel>{cur.label}</CurrencyLabel>
                    </CurrencyInfo>
                    <CurrencyCode>{cur.code}</CurrencyCode>

                    </CurrencyOption>
                  ))}
                </CurrencySelect>
              </>
            )}

          </ModalContent>

          <ModalButtonContainer>
            <ModalCloseButton onClick={ () => {close();}}>{t("close")}</ModalCloseButton>
            <ModalSaveButton onClick={handleSaveChanges}>{t("change")}</ModalSaveButton>
          </ModalButtonContainer>
          
        </Modal>

      </LanguageDropdownContainer>    
         
    </>
  )
};

export default LanguageButton

export const LanguageDropdownContainer  = styled.div`
  display: inline-block;
  position: relative;
`;

export const ModalContent = styled.div`
  padding: 30px 5px;
`;

export const IconLanguageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const LanguageSelect = styled.div`

`;

export const LanguageOption  = styled.button`
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #fff;
  color: black;
  padding: 15px;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const CurrencySelect = styled.div`

`;

export const CurrencyOption   = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: black;
  padding: 15px;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  } 
`;

const CurrencyInfo = styled.div`
  display: flex;
  align-items: center;
`;

const CurrencySymbol = styled.span`
  font-size: 20px;
  margin-right: 10px;
`;

const CurrencyLabel = styled.span`
  font-size: 16px;
`;

const CurrencyCode = styled.span`
  font-size: 14px;
  color: #777;
`;

export const LanguageFlag = styled.img`
  width: 50px;
  height: auto;
`;

export const LanguageFlagLabel = styled.span`
  font-size: 15px;
  margin-left: 10px;
`;

export const LanguageButtonWrapper = styled.button`
  display : flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-width: ${(props) => props.minWidth || "150px"};
  background-color: transparent;
  color: rgb(192, 192, 192);
  padding: 10px;
  font-size: 16px;
  // border: 1px solid #033036;
  border: ${(props) => props.border || "none"};
  border-radius: 5px;
  cursor: pointer;

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    min-width: 102px;
  }
`;
export const LanguageButtonChange = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f9fa;
  color: #212529;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e9ecef;
  }
`;
export const CurrencyButtonChange = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f9fa;
  color: #212529;
  margin-top: 15px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e9ecef;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  color: #333;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const ModalSaveButton = styled.button`
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  border: none;

  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;
